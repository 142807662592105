import React from 'react';

import { AppointmentType, Clinic, Lab, TimeSlot } from 'kb-shared/types';
import { isDisneyPatient } from 'kb-shared/utilities/lab.helper';

import { AppointmentTimeSlotsSelection } from '../../components/BookingCalendarTime/AppointmentTimeSlotsSelection';
import { BookingCalendarTime } from '../../components/BookingCalendarTime/BookingCalendarTime';
import {
  DEFAULT_FALLBACK,
  DISNEY_FALLBACK
} from '../../components/BookingCalendarTime/MissingTimeSlotsFallback';
import { StepHeading, StepHeadingContainer } from './Time.styled';

export const TimeStep = ({
  providerId,
  date,
  appointmentType,
  selectedClinic,
  selectedLab,
  selectedTimeSlot,
  onTimeSlotSelect
}: {
  providerId?: number;
  date?: string;
  appointmentType?: AppointmentType;
  selectedClinic?: Clinic;
  selectedLab?: Lab;
  selectedTimeSlot?: TimeSlot;
  onTimeSlotSelect: (timeSlot: TimeSlot) => void;
}) => {
  const disneyPatient = isDisneyPatient(selectedLab?.id ?? '');

  if (!appointmentType || (selectedClinic == null && appointmentType && !appointmentType.virtual)) {
    return disneyPatient ? DISNEY_FALLBACK : DEFAULT_FALLBACK;
  }

  return (
    <AppointmentTimeSlotsSelection
      labId={selectedLab ? parseInt(selectedLab.id) : null}
      locationId={selectedClinic ? Number(selectedClinic.id) : null}
      appointmentType={appointmentType}
      selectedClinic={selectedClinic ?? null}
      providerId={providerId}
      fallback={disneyPatient ? DISNEY_FALLBACK : DEFAULT_FALLBACK}
    >
      <StepHeadingContainer>
        <StepHeading>What date & time works best for you?</StepHeading>
      </StepHeadingContainer>
      {(selectedClinic || appointmentType?.virtual) && appointmentType && (
        <BookingCalendarTime
          date={date}
          labId={selectedLab && parseInt(selectedLab.id)}
          locationId={selectedClinic ? selectedClinic.id : null}
          appointmentType={appointmentType}
          selectedTimeSlot={selectedTimeSlot ?? null}
          providerId={providerId}
          currentDayCanBeSelected={appointmentType.name !== 'AMH Blood Draw'}
          selectedLabTimeZone={selectedClinic?.timeZone || selectedLab?.timeZone || ''}
          onSelectTimeSlot={(slot: TimeSlot | null) => {
            if (slot != null) {
              onTimeSlotSelect(slot);
            }
          }}
        />
      )}
    </AppointmentTimeSlotsSelection>
  );
};
